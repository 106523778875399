/* eslint-disable max-nested-callbacks */
import { Checkbox, FormControlLabel, Grid } from '@mui/material'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import AgriAction from '../agri/actions/AgriAction'
import { push } from 'react-router-redux'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import InstallationsAction from '../referencials/installations/actions/InstallationsAction'
import LoadingCard from '../components/cards/LoadingCard'
import { darkTextColor, lightGrey, ongoingWhite, textColor, toDoOrange, validateGreen, veryLightGrey } from '../components/styled/theme'
import { LightCard } from '../components/styled/grid'
import { Today } from '@mui/icons-material'
import moment from 'moment'
import AccordeonDesktop from '../components/AccordeonDesktop'
import { getSetting } from '../../../utils/FormUtils'
import InfosValidation from './components/steps/InfosValidation'
import ReferencialAction from '../referencials/actions/ReferencialAction'
import ContactsValidation from './components/steps/ContactsValidation'
import PointsValidation from './components/steps/PointsValidation'
import { DECLA_LAST_STEP, DECLA_POINTS_ADVANCEMENT, DECLARATION_STATUS, POINT_ADVANCEMENT_DECLARATION } from '../agri/constants/AgriConstants'
import { keys } from 'lodash'
import CommentsStep from './components/steps/CommentsStep'
import { MainButton, TextButton } from '../components/styled/buttons'
import useLocalStorage from '../../../utils/customHooks/useLocalStorage'

const Declaration = () => {
    const {
        survey,
        declaration,
        exploitation,
        accountUser,
    } = useSelector((store) => ({
        survey: store.AgriReducer.survey,
        declaration: store.AgriReducer.declaration,
        exploitation: store.AgriReducer.exploitation,
        accountUser: store.AccountReducer.accountUser,
    }), shallowEqual)

    const [dataLoaded, setDataLoaded] = useState(false)
    const [isChecked, setIsChecked] = useState(false)
    const [pointsAdvancement, setPointsAdvancement] = useLocalStorage(DECLA_POINTS_ADVANCEMENT, (exploitation?.link_samplings?.map((linkPoint) => (
        {
            idDeclaration: declaration.idDeclaration,
            idPoint: linkPoint.idInstallation,
            status: POINT_ADVANCEMENT_DECLARATION.TO_COMPLETE
        }
    ))))
    const filteredPointsAdvancement = useMemo(() => pointsAdvancement.filter(a => a.idDeclaration === declaration.idDeclaration), [pointsAdvancement])

    const [newDeclaration, setNewDeclaration] = useState(declaration)
    const [validationStep, setValidationStep] = useState(false)
    const [expanded, setExpanded] = useState(declaration.lastStep)
    const top = useRef()

    const isAdmin = useMemo(() => accountUser.isAdmin === '1' || accountUser.metadata === '1', [accountUser])

    const dispatch = useDispatch()

    const handleChange = (panel) => {
        if (panel === expanded) {
            setExpanded(declaration.lastStep)
        } else if (panel <= declaration.lastStep) {
            setExpanded(panel)
        }
    }

    useEffect(() => {
        setExpanded(declaration.lastStep)
        if (validationStep || declaration.statusCode === DECLARATION_STATUS.SENT) {
            top.current?.scrollTo(0, 0)
        }
    }, [declaration.lastStep, validationStep, declaration.statusCode])

    const getDeclaration = () => {
        dispatch(InstallationsAction.fetchInstallationsByExploitationId(exploitation?.idExploitation)).then(() => {
            dispatch(AgriAction.fetchDeclarationByExploitationId(exploitation?.idExploitation)).then(() => {
                if (declaration.idDeclaration) {
                    dispatch(AgriAction.fetchDeclarationContributors(declaration.idSurvey)).then(() => {
                        dispatch(AgriAction.fetchSurvey(declaration.idSurvey)).then(() => {
                            setDataLoaded(true)
                        })
                    })
                } else {
                    dispatch(push('/home'))
                }
            })
        })
    }

    useEffect(() => {
        dispatch(ReferencialAction.fetchCodesSandre())
        if (!exploitation?.idExploitation) {
            dispatch(AgriAction.fetchExploitation()).then(() => {
                getDeclaration()
            })
        } else {
            getDeclaration()
        }
        if (!filteredPointsAdvancement.length && exploitation) {
            setPointsAdvancement([
                ...pointsAdvancement,
                ...exploitation?.link_samplings?.map((linkPoint) => (
                    {
                        idDeclaration: declaration.idDeclaration,
                        idPoint: linkPoint.idInstallation,
                        status: POINT_ADVANCEMENT_DECLARATION.TO_COMPLETE
                    }
                ))
            ])
        }
    }, [exploitation])

    const getAdvancement = (advancement) => {
        if (declaration.lastStep === advancement) {
            return POINT_ADVANCEMENT_DECLARATION.ONGOING
        } else if (declaration.lastStep > advancement) {
            return POINT_ADVANCEMENT_DECLARATION.VALIDATED
        }
        return POINT_ADVANCEMENT_DECLARATION.TO_COMPLETE
    }

    const AdvancementIndicator = (advancement, number) => {
        switch (advancement) {
            default : case POINT_ADVANCEMENT_DECLARATION.TO_COMPLETE :
                return {
                    sx: { backgroundColor: toDoOrange },
                    content: `${number ?? ''} ${number ? i18n.toComplete : i18n.toCompleteWithMaj}`
                }
            case POINT_ADVANCEMENT_DECLARATION.ONGOING :
                return {
                    sx: { backgroundColor: ongoingWhite },
                    content: `${number ?? ''} ${number ? i18n.ongoing : i18n.ongoingWithMaj}`
                }
            case POINT_ADVANCEMENT_DECLARATION.VALIDATED :
                return {
                    sx: { backgroundColor: validateGreen },
                    content: `${number ?? ''} ${number ? i18n.validatedM : i18n.validatedMWithMaj}`
                }
        }
    }

    const Title = (number, title) => {
        const advancement = getAdvancement(number)
        return (
            <Grid container flexWrap='nowrap' alignItems='center' justifyContent='space-between'>
                <Grid container item alignItems='center' columnGap='1vw' sx={{ fontWeight: 500, fontSize: '16px', lineHeight: '24px', letterSpacing: '0.15px' }}>
                    <Grid item sx={{ color: lightGrey }}>{number}.</Grid>
                    <Grid item sx={{ color: darkTextColor }}>{title}</Grid>
                </Grid>
                {validationStep || declaration.statusCode === DECLARATION_STATUS.SENT ?
                    null
                    :
                    (
                        number === 3 && declaration.lastStep === DECLA_LAST_STEP.POINTS_STEP ?
                            <Grid container justifyContent='flex-end' sx={{ columnGap: '0.5vw', marginRight: '3vw' }}>
                                {
                                    keys(POINT_ADVANCEMENT_DECLARATION).map((_, i) => {
                                        const num = filteredPointsAdvancement.filter(a => a.status === i)?.length
                                        const { sx, content } = AdvancementIndicator(i, num)
                                        return (
                                            <Grid
                                                item
                                                xs={3.5}
                                                container
                                                alignItems='center'
                                                justifyContent='center'
                                                sx={{
                                                    borderRadius: '8px',
                                                    border: `solid 1px ${veryLightGrey}`,
                                                    padding: '1vh 1vw',
                                                    fontSize: '14px',
                                                    lineHeight: '20px',
                                                    letterSpacing: '0.1px',
                                                    color: 'black',
                                                    ...sx,
                                                }}
                                            >
                                                {content}
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                            :
                            <Grid
                                item
                                xs={4}
                                md={3}
                                lg={2}
                                xl={1.5}
                                container
                                alignItems='center'
                                justifyContent='center'
                                sx={{
                                    marginRight: '3vw',
                                    borderRadius: '8px',
                                    border: `solid 1px ${veryLightGrey}`,
                                    padding: '1vh 1vw',
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                    letterSpacing: '0.1px',
                                    color: 'black',
                                    ...AdvancementIndicator(advancement)?.sx
                                }}
                            >
                                {AdvancementIndicator(advancement)?.content}
                            </Grid>
                    )
                }
            </Grid>
        )
    }


    const getTitleAsksAndConsos = () => {
        const consoIsActive = (getSetting(survey?.link_params, 'consos') ?? '1') === '1'
        const askIsActive = (getSetting(survey?.link_params, 'usagesPrevi') ?? '1') === '1'
        if (consoIsActive) {
            if (askIsActive) {
                return i18n.consoAndAskByPoint
            }
            return i18n.consoByPoint
        } else if (askIsActive) {
            return i18n.askByPoint
        }
        return i18n.verifPointsInfos
    }

    if (!dataLoaded) {
        return <LoadingCard />
    }

    return (
        <Grid
            container
            rowGap='3vh'
            alignContent= 'flex-start'
            sx={{
                padding: '5vh',
                maxHeight: 'calc(100% - 4vh)',
                overflowY: 'hidden',
                width: '100%',
            }}
        >
            <Grid container alignItems='center' rowGap='2vh'>
                <Grid item xs={validationStep && declaration.statusCode !== DECLARATION_STATUS.SENT ? 4 : 12} sx={{ fontSize: '22px', lineHeight: '28px', color: textColor }}>
                    {declaration.statusCode === DECLARATION_STATUS.SENT ? i18n.congratsDeclaEnterWithSuccess : (validationStep ? i18n.declaValidation : `${i18n.theSurvey} ${survey.name} ${i18n.isOpen}`)}
                </Grid>
                <LightCard
                    item
                    xs={validationStep && declaration.statusCode !== DECLARATION_STATUS.SENT ? 8 : 12}
                    container
                    alignItems= 'center'
                    justifyContent= 'center'
                    columnGap= '1vw'
                    sx={{ fontSize: '16px', lineHeight: '24px', letterSpacing: '0.15px', color: textColor }}
                >
                    <Today />
                    <Grid item>
                        {declaration.statusCode === DECLARATION_STATUS.SENT ? i18n.modifSurveyTill : i18n.enterSurveyTill} <b>{moment(survey.endDate).format('dddd Do MMMM YYYY')}</b>&nbsp;
                        {isAdmin ? (
                            <>
                                ({i18n.adminEndDate} : <b>{moment(survey.adminEndDate).format('dddd Do MMMM YYYY')}</b>)
                            </>
                        ) : null}
                    </Grid>
                </LightCard>
                {validationStep && declaration.statusCode !== DECLARATION_STATUS.SENT &&
                    <Grid item container rowGap='1vh'>
                        <Grid
                            item
                            container
                            sx={{
                                color: textColor,
                                fontSize: '16px',
                                fontWeight: '500',
                                lineHeight: '24px',
                                letterSpacing: '0.15px'
                            }}
                        >
                            {i18n.recapInfos}
                        </Grid>
                        <Grid
                            item
                            container
                            sx={{
                                color: textColor,
                                fontSize: '14px',
                                fontWeight: '400',
                                lineHeight: '20px',
                                letterSpacing: '0.25px'
                            }}
                        >
                            {i18n.pleaseCheckGivenInfosThenValidForm}
                        </Grid>
                    </Grid>
                }
                {declaration.statusCode === DECLARATION_STATUS.SENT &&
                    <TextButton
                        onClick={() => {
                            setIsChecked(false)
                            setValidationStep(true)
                            dispatch(AgriAction.updateDeclaration({ ...declaration, statusCode: DECLARATION_STATUS.ONGOING })).then(() => {
                                dispatch(AgriAction.fetchDeclarationByExploitationId(exploitation.idExploitation)).then((decla) => {
                                    window.scrollTo(0, 0)
                                    setNewDeclaration(decla)
                                })
                            })
                        }}
                    >
                        {i18n.edit}
                    </TextButton>
                }
            </Grid>
            <Grid item container rowGap='2vh'>
                <Grid item container sx={{ overflowY: 'auto', height: '59vh' }} ref={top}>
                    <AccordeonDesktop
                        defaultExpanded={declaration.lastStep === DECLA_LAST_STEP.COMMENTS_STEP}
                        expanded={(expanded === 1 || validationStep || declaration.statusCode === DECLARATION_STATUS.SENT) ?? false}
                        onChange={() => handleChange(1)}
                        item
                        transparent
                        xs={12}
                        sx={{
                            width: '100%',
                        }}
                        title={Title(1, i18n.infosValidation)}
                    >
                        <InfosValidation readMode={declaration.lastStep > DECLA_LAST_STEP.PERSONNAL_INFO_STEP} setValidationStep={setValidationStep}/>
                    </AccordeonDesktop>
                    <AccordeonDesktop
                        defaultExpanded={declaration.lastStep === DECLA_LAST_STEP.CONTACTS_STEP}
                        expanded={(expanded === 2 || validationStep || declaration.statusCode === DECLARATION_STATUS.SENT) ?? false}
                        onChange={() => handleChange(2)}
                        transparent
                        item
                        xs={12}
                        sx={{
                            width: '100%',
                        }}
                        title={Title(2, i18n.contactsValidation)}
                    >
                        <ContactsValidation setValidationStep={setValidationStep}/>
                    </AccordeonDesktop>
                    <AccordeonDesktop
                        defaultExpanded={declaration.lastStep === DECLA_LAST_STEP.POINTS_STEP ?? false}
                        expanded={(expanded === 3 || validationStep || declaration.statusCode === DECLARATION_STATUS.SENT) ?? false}
                        onChange={() => handleChange(3)}
                        transparent
                        item
                        xs={12}
                        sx={{
                            width: '100%',
                        }}
                        title={Title(3, getTitleAsksAndConsos())}
                    >
                        <PointsValidation setValidationStep={setValidationStep}/>
                    </AccordeonDesktop>
                    <AccordeonDesktop
                        defaultExpanded={declaration.lastStep === DECLA_LAST_STEP.COMMENTS_STEP ?? false}
                        expanded={(expanded === 4 || validationStep || declaration.statusCode === DECLARATION_STATUS.SENT) ?? false}
                        onChange={() => handleChange(4)}
                        transparent
                        item
                        xs={12}
                        sx={{
                            width: '100%',
                        }}
                        title={Title(4, `${i18n.addComment} (${i18n.optional})`)}
                    >
                        <Grid container justifyContent='center' sx={{ rowGap: '3vh', flexWrap: 'wrap' }}>
                            <CommentsStep
                                xs={7}
                                newDeclaration={newDeclaration}
                                setNewDeclaration={setNewDeclaration}
                                setValidationStep={setValidationStep}
                            />
                        </Grid>
                    </AccordeonDesktop>
                    {validationStep && declaration.statusCode != DECLARATION_STATUS.SENT &&
                        <Grid container item justifyContent='center' rowGap='1vh'>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        value={isChecked || null}
                                        checked={isChecked}
                                        onChange={() => setIsChecked(!isChecked)}
                                    />
                                }
                                sx={{
                                    width: '70%',
                                    textAlign: 'center'
                                }}
                                label={i18n.allInformationsTrue}
                            />
                            <Grid container xs={12} justifyContent='center'>
                                <MainButton
                                    noFullWidth
                                    sx={{ width: '20%' }}
                                    disabled={!isChecked}
                                    onClick={() => {
                                        dispatch(AgriAction.updateDeclaration({ ...declaration, statusCode: DECLARATION_STATUS.SENT })).then(() => {
                                            dispatch(AgriAction.fetchDeclarationByExploitationId(exploitation.idExploitation))
                                        })
                                    }}
                                >
                                    {i18n.validate}
                                </MainButton>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Declaration